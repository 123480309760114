import React, { useState, useEffect } from 'react';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Leaf, ArrowRight, Award, Coins, Check, X, UserPlus, LogIn } from 'lucide-react';
import { toastSuccess, toastError, toastInfo } from "@/hooks/use-toast";
import CarbonNFT from './CarbonNFT';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";

interface CarbonSimulatorProps {
  authContext?: any;
}

const CarbonSimulator = ({ authContext }: CarbonSimulatorProps) => {
  const [projectSize, setProjectSize] = useState<number | ''>('');
  const [projectName, setProjectName] = useState<string>("");
  const [estimatedCredits, setEstimatedCredits] = useState<number>(0);
  const [isCreatingNFT, setIsCreatingNFT] = useState<boolean>(false);
  const [nftCreated, setNftCreated] = useState<boolean>(false);
  const [nftId, setNftId] = useState<string>('');
  const [showRegisterDialog, setShowRegisterDialog] = useState<boolean>(false);
  const [showLoginDialog, setShowLoginDialog] = useState<boolean>(false);
  
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [isRegistering, setIsRegistering] = useState<boolean>(false);
  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
  
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<any>(null);
  
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (authContext) {
      setIsAuthenticated(authContext.isAuthenticated || false);
      setUser(authContext.user || null);
    }
  }, [authContext]);

  const login = (user: any) => {
    if (authContext && typeof authContext.login === 'function') {
      authContext.login(user);
    } else {
      setIsAuthenticated(true);
      setUser(user);
      console.log("User logged in (mock):", user);
    }
  };

  useEffect(() => {
    if (searchParams.get('action') === 'buy') {
      navigate('/projects', { replace: true });
      toastInfo("Projets disponibles", "Voici les projets disponibles pour l'achat de crédits carbone.");
    }
    
    if (location.state?.from === 'register') {
      setShowRegisterDialog(true);
    }

    if (location.state?.from === 'login') {
      setShowLoginDialog(true);
    }
  }, [searchParams, navigate, location]);
  
  const calculateCredits = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    
    if (!projectSize || projectSize <= 0) {
      toastError("Erreur", "Veuillez entrer une surface valide (plus grande que 0)");
      return;
    }
    
    const credits = Number(projectSize) * 5;
    setEstimatedCredits(credits);
    
    toastSuccess("Estimation réussie", `${credits} crédits carbone estimés pour votre projet.`);
  };

  const generateNFTId = () => {
    return '0x' + Array.from(crypto.getRandomValues(new Uint8Array(8)))
      .map(b => b.toString(16).padStart(2, '0'))
      .join('')
      .toUpperCase();
  };

  const createMockNFT = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    
    if (estimatedCredits <= 0) {
      toastError("Erreur", "Veuillez d'abord calculer vos crédits carbone.");
      return;
    }

    if (!projectName.trim()) {
      toastError("Erreur", "Veuillez saisir un nom de projet ou de propriétaire.");
      return;
    }

    setIsCreatingNFT(true);
    
    const newNftId = generateNFTId();
    setNftId(newNftId);
    
    setTimeout(() => {
      setIsCreatingNFT(false);
      setNftCreated(true);
      toastSuccess("NFT créé avec succès!", `${estimatedCredits} crédits carbone ont été tokenisés en NFT.`);
    }, 2000);
  };

  const resetForm = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    
    setProjectSize('');
    setProjectName("");
    setEstimatedCredits(0);
    setNftCreated(false);
    setNftId('');
    toastInfo("Formulaire réinitialisé", "Vous pouvez maintenant créer un nouveau projet.");
  };

  const navigateToProjects = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    navigate('/projects', { replace: true });
    toastInfo("Navigation", "Redirection vers les projets disponibles.");
  };

  const handleRegisterSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setIsRegistering(true);
    
    setTimeout(() => {
      const userId = `user_${Math.random().toString(36).substr(2, 9)}`;
      
      const newUser = {
        id: userId,
        name: name,
        email: email
      };
      
      login(newUser);
      
      setIsRegistering(false);
      setShowRegisterDialog(false);
      
      toastSuccess("Inscription réussie", `Bienvenue, ${name}! Votre compte a été créé avec succès.`);
      
      navigate('/', { replace: true });
    }, 1500);
  };

  const handleLoginSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoggingIn(true);
    
    setTimeout(() => {
      const mockUser = {
        id: `user_${Math.random().toString(36).substr(2, 9)}`,
        name: email.split('@')[0],
        email: email
      };
      
      login(mockUser);
      
      setIsLoggingIn(false);
      setShowLoginDialog(false);
      
      toastSuccess("Connexion réussie", `Bienvenue, ${mockUser.name}!`);
      
      navigate('/', { replace: true });
    }, 1500);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    
    switch(name) {
      case "projectName":
        setProjectName(value);
        break;
      case "name":
        setName(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "password":
        setPassword(value);
        break;
      case "login-email":
        setEmail(value);
        break;
      case "login-password":
        setPassword(value);
        break;
      default:
        console.log("Champ non géré:", name);
    }
  };

  const handleNumberInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === '') {
      setProjectSize('');
    } else {
      const numValue = parseFloat(value);
      if (!isNaN(numValue)) {
        setProjectSize(numValue);
      }
    }
  };

  return (
    <section className="py-16 bg-brand-50">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-brand-800 mb-4">Simulateur de Crédits Carbone</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Estimez le potentiel de vos projets écologiques en crédits carbone tokenisés
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto mb-16">
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <Leaf className="text-brand-600" />
                Détails du Projet
              </CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
              <div>
                <Label htmlFor="projectName">Nom du projet ou propriétaire</Label>
                <Input
                  id="projectName"
                  name="projectName"
                  type="text"
                  value={projectName}
                  onChange={handleInputChange}
                  placeholder="Ex: Reforestation Côte d'Ivoire"
                  className="mt-1"
                  autoComplete="off"
                />
              </div>
              <div>
                <Label htmlFor="projectSize">Surface du projet (hectares)</Label>
                <Input
                  id="projectSize"
                  name="projectSize"
                  type="number"
                  value={projectSize}
                  onChange={handleNumberInputChange}
                  placeholder="Ex: 100"
                  className="mt-1"
                  min="0"
                  step="1"
                  autoComplete="off"
                />
              </div>
              <Button 
                onClick={calculateCredits}
                className="w-full bg-amber-500 hover:bg-amber-600 text-black font-medium"
                type="button"
              >
                Calculer les crédits
              </Button>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <Award className="text-brand-600" />
                Tokenisation en NFT
              </CardTitle>
              <CardDescription>
                Transformez vos crédits carbone en certificats numériques sécurisés et traçables
              </CardDescription>
            </CardHeader>
            <CardContent>
              <div className="text-center space-y-4">
                <div className="p-6 bg-brand-50 rounded-lg border-2 border-brand-200">
                  <p className="text-4xl font-bold text-brand-700">
                    {estimatedCredits}
                  </p>
                  <p className="text-sm text-gray-600 mt-2">Crédits Carbone à Tokeniser</p>
                </div>
                
                {nftCreated ? (
                  <div className="mt-4">
                    <div className="bg-green-100 text-green-700 p-6 rounded-lg flex flex-col items-center gap-4 mb-4 border border-green-300">
                      <Award className="h-12 w-12 text-green-600" />
                      <div>
                        <p className="font-semibold mb-1">NFT créé avec succès!</p>
                        <p className="text-sm">{estimatedCredits} crédits carbone tokenisés</p>
                      </div>
                      <div className="w-full max-w-xs">
                        <CarbonNFT 
                          credits={estimatedCredits} 
                          id={nftId}
                          projectName={projectName}
                        />
                      </div>
                    </div>
                    <Button 
                      onClick={resetForm}
                      className="w-full bg-amber-500 hover:bg-amber-600 text-black font-medium"
                      type="button"
                    >
                      Créer un nouveau projet
                    </Button>
                  </div>
                ) : (
                  <>
                    <div className="flex flex-col space-y-4">
                      <div className="flex items-center gap-2 text-sm text-brand-600 bg-brand-50 p-3 rounded-lg">
                        <ArrowRight className="h-4 w-4" />
                        <span>Les NFT garantissent l'unicité et la traçabilité des crédits</span>
                      </div>
                      <div className="flex items-center gap-2 text-sm text-brand-600 bg-brand-50 p-3 rounded-lg">
                        <ArrowRight className="h-4 w-4" />
                        <span>Chaque crédit est certifié et vérifiable sur la blockchain</span>
                      </div>
                    </div>
                    <Button 
                      onClick={createMockNFT}
                      className="w-full bg-amber-500 hover:bg-amber-600 text-black font-medium mt-6 flex items-center justify-center gap-2 py-3"
                      disabled={isCreatingNFT || estimatedCredits <= 0}
                      type="button"
                    >
                      {isCreatingNFT ? (
                        <>
                          <span className="animate-spin rounded-full h-5 w-5 border-b-2 border-black"></span>
                          <span>Tokenisation en cours...</span>
                        </>
                      ) : (
                        <>
                          <Award className="h-5 w-5" />
                          <span>Tokeniser en NFT</span>
                        </>
                      )}
                    </Button>
                  </>
                )}
              </div>
            </CardContent>
          </Card>
        </div>

        <Dialog open={showRegisterDialog} onOpenChange={setShowRegisterDialog}>
          <DialogContent className="sm:max-w-md">
            <DialogHeader>
              <DialogTitle>Créez votre compte Guteco</DialogTitle>
              <DialogDescription>
                Rejoignez notre communauté et commencez à investir dans des projets écologiques
              </DialogDescription>
            </DialogHeader>
            <form onSubmit={handleRegisterSubmit}>
              <div className="space-y-4 py-4">
                <div className="space-y-2">
                  <Label htmlFor="name">Nom complet</Label>
                  <Input 
                    id="name" 
                    name="name"
                    placeholder="Jean Dupont" 
                    value={name} 
                    onChange={handleInputChange} 
                    autoComplete="name"
                    required 
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="email">Email</Label>
                  <Input 
                    id="email" 
                    name="email"
                    type="email" 
                    placeholder="jean.dupont@example.com" 
                    value={email} 
                    onChange={handleInputChange} 
                    autoComplete="email"
                    required 
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="password">Mot de passe</Label>
                  <Input 
                    id="password" 
                    name="password"
                    type="password" 
                    value={password} 
                    onChange={handleInputChange} 
                    autoComplete="new-password"
                    required 
                  />
                </div>
              </div>
              <DialogFooter>
                <Button 
                  type="submit"
                  className="bg-highlight hover:bg-highlight-hover text-black w-full flex items-center justify-center gap-2"
                  disabled={isRegistering}
                >
                  {isRegistering ? (
                    <>
                      <span className="animate-spin rounded-full h-4 w-4 border-b-2 border-black"></span>
                      <span>Création en cours...</span>
                    </>
                  ) : (
                    <>
                      <UserPlus className="h-4 w-4" />
                      <span>Créer mon compte</span>
                    </>
                  )}
                </Button>
              </DialogFooter>
            </form>
          </DialogContent>
        </Dialog>

        <Dialog open={showLoginDialog} onOpenChange={setShowLoginDialog}>
          <DialogContent className="sm:max-w-md">
            <DialogHeader>
              <DialogTitle>Connexion à votre compte</DialogTitle>
              <DialogDescription>
                Connectez-vous pour accéder à vos projets et investissements
              </DialogDescription>
            </DialogHeader>
            <form onSubmit={handleLoginSubmit}>
              <div className="space-y-4 py-4">
                <div className="space-y-2">
                  <Label htmlFor="login-email">Email</Label>
                  <Input 
                    id="login-email" 
                    name="login-email"
                    type="email" 
                    placeholder="jean.dupont@example.com" 
                    value={email} 
                    onChange={handleInputChange}
                    autoComplete="email" 
                    required 
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="login-password">Mot de passe</Label>
                  <Input 
                    id="login-password" 
                    name="login-password"
                    type="password" 
                    value={password} 
                    onChange={handleInputChange}
                    autoComplete="current-password" 
                    required 
                  />
                </div>
              </div>
              <DialogFooter>
                <Button 
                  type="submit"
                  className="bg-highlight hover:bg-highlight-hover text-black w-full flex items-center justify-center gap-2"
                  disabled={isLoggingIn}
                >
                  {isLoggingIn ? (
                    <>
                      <span className="animate-spin rounded-full h-4 w-4 border-b-2 border-black"></span>
                      <span>Connexion en cours...</span>
                    </>
                  ) : (
                    <>
                      <LogIn className="h-4 w-4" />
                      <span>Se connecter</span>
                    </>
                  )}
                </Button>
              </DialogFooter>
            </form>
          </DialogContent>
        </Dialog>

        <div className="text-center mt-12">
          <h3 className="text-xl font-semibold text-brand-800 mb-4">Vous souhaitez investir dans des projets existants?</h3>
          <p className="text-gray-600 max-w-2xl mx-auto mb-6">
            Parcourez notre catalogue de projets écologiques certifiés et achetez des crédits carbone pour compenser votre empreinte.
          </p>
          <Button 
            className="bg-brand-600 hover:bg-brand-700 px-8 py-3 text-white flex items-center gap-2 mx-auto"
            onClick={navigateToProjects}
            type="button"
          >
            <Coins className="h-5 w-5" />
            <span>Voir tous les projets disponibles</span>
          </Button>
        </div>
      </div>
    </section>
  );
};

export default CarbonSimulator;
