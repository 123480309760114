
import { useState, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import Index from './pages/Index';
import Team from './pages/Team';
import Projects from './pages/Projects';
import NotFound from './pages/NotFound';
import TokenEconomics from './pages/TokenEconomics';
import Simulator from './pages/Simulator';
import VCarbs from './pages/VCarbs';
import Valuation from './pages/Valuation';
import Dao from './pages/Dao';
import DaoPitch from './pages/DaoPitch';
import Community from './pages/Community';
import ExpectedReturns from './pages/ExpectedReturns';
import VerifyNFT from './pages/VerifyNFT';
import AIInfluencer from './pages/AIInfluencer';
import AIVideoScript from './pages/AIVideoScript';
import InvestmentMemorandum from './pages/InvestmentMemorandum';
import Incubation from './pages/Incubation';
import Legal from './pages/Legal';
import Error from './pages/Error';
import { Toaster } from "./components/ui/toaster";
import { AuthProvider } from './contexts/AuthContext';
import { LanguageProvider } from './contexts/LanguageContext';

// ScrollToTop component to ensure page scrolls to top on route change
function ScrollToTop() {
  const { pathname } = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  return null;
}

function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    window.addEventListener('resize', handleResize);
    
    // Detect if device is in portrait or landscape
    const mediaQuery = window.matchMedia("(orientation: portrait)");
    const handleOrientationChange = (e: MediaQueryListEvent) => {
      document.documentElement.classList.toggle('portrait', e.matches);
      document.documentElement.classList.toggle('landscape', !e.matches);
    };
    
    // Initialize orientation classes
    document.documentElement.classList.toggle('portrait', mediaQuery.matches);
    document.documentElement.classList.toggle('landscape', !mediaQuery.matches);
    
    // Add listener for orientation changes
    mediaQuery.addEventListener('change', handleOrientationChange);
    
    // Initial tablet detection
    const isTablet = window.innerWidth >= 768 && window.innerWidth <= 1024;
    document.documentElement.classList.toggle('tablet-layout', isTablet);
    
    return () => {
      window.removeEventListener('resize', handleResize);
      mediaQuery.removeEventListener('change', handleOrientationChange);
    };
  }, []);
  
  return (
    <LanguageProvider>
      <AuthProvider>
        <ScrollToTop />
        <div className="app-container">
          <Routes>
            <Route path="/" element={<Index />} />
            <Route path="/team" element={<Team />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/token-economics" element={<TokenEconomics />} />
            <Route path="/simulator" element={<Simulator />} />
            <Route path="/vcarbs" element={<VCarbs />} />
            <Route path="/valuation" element={<Valuation />} />
            <Route path="/dao" element={<Dao />} />
            <Route path="/dao-pitch" element={<DaoPitch />} />
            <Route path="/community" element={<Community />} />
            <Route path="/expected-returns" element={<ExpectedReturns />} />
            <Route path="/verify-nft/:id" element={<VerifyNFT />} />
            <Route path="/ai-influencer" element={<AIInfluencer />} />
            <Route path="/ai-video-script" element={<AIVideoScript />} />
            <Route path="/investment-memorandum" element={<InvestmentMemorandum />} />
            <Route path="/incubation" element={<Incubation />} />
            <Route path="/legal" element={<Legal />} />
            <Route path="/error" element={<Error />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        <Toaster />
      </AuthProvider>
    </LanguageProvider>
  );
}

export default App;
