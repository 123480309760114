
import React, { useState, useMemo } from 'react';
import { Leaf, QrCode, Shield } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { toastError } from '@/hooks/use-toast';

interface CarbonNFTProps {
  credits: number;
  id: string;
  projectName?: string;
}

const CarbonNFT = ({ credits, id, projectName = "Mon Projet" }: CarbonNFTProps) => {
  const navigate = useNavigate();
  const [verificationInProgress, setVerificationInProgress] = useState(false);
  
  // Utilisation de useMemo pour réduire les recalculs inutiles
  const secureId = useMemo(() => {
    // Cryptage simple de l'identifiant pour l'affichage
    return id.length > 8 ? `${id.slice(0, 4)}...${id.slice(-4)}` : id;
  }, [id]);
  
  // Handler sécurisé avec limitation de débit
  const handleVerify = (e: React.MouseEvent) => {
    e.preventDefault(); // Prevent default behavior
    
    // Prévention du double-clic avec délai
    if (verificationInProgress) {
      return;
    }
    
    setVerificationInProgress(true);
    
    try {
      // Validation des données avant navigation
      if (!id || id.trim() === '') {
        throw new Error('Identifiant NFT invalide');
      }
      
      if (isNaN(credits) || credits < 0) {
        throw new Error('Valeur de crédits invalide');
      }
      
      // URL encoding pour sécuriser les paramètres
      const secureProjectName = encodeURIComponent(projectName);
      
      // Création d'un chemin sécurisé pour la navigation
      navigate(`/verify-nft/${id}?credits=${credits}&project=${secureProjectName}`, { replace: true });
      
      // Journalisation pour audit
      console.log('Vérification NFT initiée:', {
        timestamp: new Date().toISOString(),
        tokenId: secureId,
        credits
      });
    } catch (error) {
      console.error('Erreur lors de la vérification:', error);
      toastError("Erreur de vérification", "Impossible de vérifier ce certificat. Veuillez réessayer.");
    } finally {
      // Rétablir l'état après un court délai
      setTimeout(() => {
        setVerificationInProgress(false);
      }, 1000);
    }
  };
  
  return (
    <div className="w-full">
      <div className="border border-gray-200 rounded-lg overflow-hidden bg-white shadow-md">
        <div className="p-4">
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center gap-2">
              <Leaf className="h-6 w-6 text-green-500" />
              <span className="font-semibold text-green-600">ECO-NFT</span>
            </div>
            <span className="text-xs font-mono text-gray-500">{secureId}</span>
          </div>
          
          {/* Project name display with golden highlight */}
          <div className="bg-gray-50 rounded-lg p-2 mb-3">
            <span className="text-sm text-gray-600">Projet/Propriétaire:</span>
            <h4 className="font-medium text-amber-600">{projectName}</h4>
          </div>
          
          <div className="bg-gradient-to-br from-gray-100 to-gray-200 rounded-lg p-6 text-center">
            <h3 className="text-2xl font-bold text-gray-700">{credits}</h3>
            <p className="text-sm text-gray-600 mt-1">Crédits Carbone</p>
          </div>
          
          <div className="mt-4 flex flex-col gap-3">
            <div className="flex items-center justify-between text-sm">
              <span className="text-gray-600">Réseau</span>
              <div className="flex items-center gap-1">
                <Shield className="h-3 w-3 text-green-500" />
                <span className="font-medium text-gray-700">Polygon</span>
              </div>
            </div>
            
            <div className="border-t pt-3 mt-2">
              <div className="flex items-center justify-between">
                <span className="text-xs text-gray-600">Certificat de sécurité</span>
                <button 
                  className="bg-gray-100 hover:bg-gray-200 p-1 rounded transition-colors" 
                  onClick={handleVerify}
                  type="button"
                  title="Scanner pour vérifier l'authenticité"
                  disabled={verificationInProgress}
                  aria-disabled={verificationInProgress}
                >
                  <QrCode className="h-5 w-5 text-gray-600" />
                </button>
              </div>
              <div 
                className={`bg-gray-50 mt-2 p-3 rounded-md flex items-center justify-center cursor-pointer hover:bg-gray-100 transition-colors ${verificationInProgress ? 'opacity-70 pointer-events-none' : ''}`}
                onClick={handleVerify}
                role="button"
                aria-label="Vérifier l'authenticité du certificat"
                tabIndex={0}
              >
                <div className="relative">
                  <QrCode className="h-16 w-16 text-gray-800" strokeWidth={1.5} />
                  <div className="absolute inset-0 flex items-center justify-center">
                    <Leaf className="h-6 w-6 text-green-500" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarbonNFT;
